import React from "react"
import { useSelector } from "react-redux"
import ReactGA from "react-ga"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button, BlockContent, CheckBoxList } from ".."
import { useDispatch } from "../../utils"
import {
  addForbedringspunkt,
  checkUserForbedringspunkt,
  checkForbedringspunkt,
  deleteItem,
  setDirtyTitle,
  cancelEditTitle,
  updateForbedringspunkt,
} from "../../state/forbedringspunkter/actions"

import { IndustryType, SubjectType } from "../../propTypes"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const Forbedringspunkter = ({ industry, subject, page }) => {
  const forbedringspunkter = Object.values(useSelector(state => state.forbedringspunkter)).filter(
    f => f.subjectId === subject._id && f.industryId === industry._id
  )

  const dispatch = useDispatch()

  // const stateLocalStorage = loadState()
  // const language =
  //   stateLocalStorage.languageOption !== undefined
  //     ? stateLocalStorage.languageOption.languageOption
  //     : ""

  const removedEmpty = page?.formulationItemsRefs.filter(element => {
    if (Object.keys(element).length !== 0) {
      return true
    }
    return false
  })

  // let titleLanguage = ""
  const forbedringspunkterList = removedEmpty?.map(item => {
    const forbedringspunkt = forbedringspunkter.find(f => f.dataId === item.id)
    // if (language === "no") {
    //   titleLanguage = item.title_no
    // } else if (language === "en") {
    //   titleLanguage = item.title_en
    // } else {
    //   titleLanguage = item.title
    // }
    return {
      id: forbedringspunkt ? forbedringspunkt.id : item.id,
      title: item.title,
      isChecked: forbedringspunkt ? forbedringspunkt.isChecked : false,
      isNew: false,
      dataId: item._id,
    }
  })

  const forbedringspunkterListLostId = forbedringspunkter
    .filter(forbedringspunkt => forbedringspunkt.isUserDefined === false)
    .map(item => {
      const forbedringspunkt = removedEmpty.find(f => f._id === item.dataId)
      if (!forbedringspunkt) {
        return {
          id: item.id,
          title: item.title,
          isChecked: item.isChecked,
          isNew: false,
          dataId: null,
          isUserDefined: true,
        }
      }
      return null
    })
    .filter(forbedringspunkt => forbedringspunkt !== null)

  const userForbedringspunkterList = forbedringspunkter
    .filter(forbedringspunkt => forbedringspunkt.isUserDefined)
    .concat(forbedringspunkterListLostId)
    .map(f => {
      return {
        id: f.id,
        title: f.title.text,
        isChecked: f.isChecked,
        isNew: f.isNew,
        dataId: null,
      }
    })

  const onCheckForbedringspunkt = forbedringspunktId => {
    const forbedringspunkt = forbedringspunkterList.find(f => f.id === forbedringspunktId)

    ReactGA.event({
      category: "Forbedringspunkt",
      action: `Clicked ${forbedringspunktId} ${forbedringspunkt.title}`,
      label: window.location.pathname + window.location.search,
    })
    ReactGA.pageview(window.location.pathname + window.location.search)
    dispatch(
      checkForbedringspunkt({
        forbedringspunktId,
        industryId: industry._id,
        subjectId: subject._id,
        dataId: forbedringspunkt.dataId,
        title: forbedringspunkt.title,
      })
    )
  }

  const onCheckUserForbedringspunkt = forbedringspunktId => {
    dispatch(checkUserForbedringspunkt(forbedringspunktId))
  }

  const onNewForbedringspunktClicked = () => {
    dispatch(addForbedringspunkt(industry._id, subject._id))
  }

  return (
    <>
      {page ? (
        <div className={classNames(styles.forbedringspunkter, styles.yellowBackground)}>
          <fieldset>
            <legend style={{ display: "none" }}>{page.title}</legend>
            <H className="h3">{page.title}</H>
            <BlockContent blocks={page.premadeIngress || []} />

            <CheckBoxList items={forbedringspunkterList} onItemChecked={onCheckForbedringspunkt} />

            <BlockContent blocks={page.userIngress || []} />

            {userForbedringspunkterList && (
              <CheckBoxList
                items={userForbedringspunkterList}
                onItemChecked={onCheckUserForbedringspunkt}
                canEdit
                editBtnText={page.editBtnText}
                deleteBtnText={page.deleteBtnText}
                saveBtnText={page.saveBtnText}
                setDirtyTitle={setDirtyTitle}
                cancelEditTitle={cancelEditTitle}
                updateItem={updateForbedringspunkt}
                deleteItem={deleteItem}
              />
            )}
          </fieldset>
          <Button.AddNew onClick={onNewForbedringspunktClicked} title={page.newPointText} />
        </div>
      ) : (
        <p style={{ color: "red" }}>
          Temaet <span style={{ fontWeight: 500 }}>{subject.title}</span> er ikke fylt ut i{" "}
          <span style={{ fontWeight: 500 }}>Standard Tekster</span>. Mangler innhold i{" "}
          <span style={{ fontWeight: 500 }}>Forbedringspunkter</span>
        </p>
      )}
    </>
  )
}

Forbedringspunkter.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType.isRequired,
  page: PropTypes.any.isRequired,
}

export default Forbedringspunkter
